import { defineStore } from 'pinia'
import { ApiCommon } from '@/utils/api'
import { SystemSetting } from '@/utils/interface/ISetting'
export const useSystemStore = defineStore('useSystemStore', {
  state() {
    let setting = {
      message: {
        open: false,
      },
      friend: {
        open: false,
      },
      comment: {
        open: false,
      },
      notice: {
        open: false,
      },
    } as SystemSetting
    return {
      code: 0,
      setting,
    }
  },
  getters: {},
  actions: {
    setCode(code: number) {
      this.code = code
    },
    async getSetting() {
      // getSetting
      const res = await ApiCommon.getSetting()
      this.setting = res.data
    },
    changeSetting() {
      this.setting.friend.open = true
    }
  },
})
