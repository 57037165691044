import { useSystemStore } from '@/store';
import axios, { AxiosRequestConfig } from 'axios';

export function request(config: AxiosRequestConfig) {
  const instance = axios.create({
    baseURL: '/api',
    timeout: 10000,
  });
  // 请求拦截器
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      // console.log("响应拦截器响应",response);
      // if(response.data.code !== "084"){
      //     return Promise.reject(response.data)
      // }
      return response;
    },
    (error) => {
      // console.log("响应拦截器错误", error)

      const systemStore = useSystemStore();
      switch (error.response.status) {
        case 404:
          systemStore.setCode(404);
          break;
        // return Promise.reject(error);
        case 429:
          systemStore.setCode(429);
          break;
        default:
          return Promise.reject(error);
      }
    }
  );
  return instance(config);
}

export default request;
