import request from '@/utils/request';
import type { IResponse, ResultResponse } from '@/utils/interface/ICommon';
import type { IArticleList, IArticleDetails } from '@/utils/interface/IArticle';
import type { IPage } from '@/utils/interface/ICommon';
import type {
  ICategory,
  ICategoryAndArticleResult,
} from '@/utils/interface/ICategory';
import type { IUserMessage } from '@/utils/interface/IMessage';
import { SystemSetting } from '@/utils/interface/ISetting';
import { Apply, Friend } from '@/utils/obj/OApply';
import { EditorContent } from '@/utils/obj/EditorContent';

export const ApiArticle = {
  getArticle(
    page: Number,
    page_size: Number,
  ): Promise<IResponse<IPage<IArticleList>>> {
    return new Promise((resolve) => {
      request({
        url: `/article`,
        params: {
          page: page,
          page_size: page_size,
        },
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  getArticleById(
    id: String
  ): Promise<IResponse<ResultResponse<IArticleDetails>>> {
    return new Promise((resolve) => {
      request({
        url: `/article/${id}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  search(keyword: string): Promise<IResponse<IArticleList[]>> {
    return new Promise((resolve) => {
      request({
        url: `/article/search?q=${keyword}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

export const ApiCategory = {
  getCategories(): Promise<IResponse<ResultResponse<ICategory[]>>> {
    return new Promise((resolve) => {
      request({
        url: `/categories`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  getArticlesByCategoryPath(
    path: string
  ): Promise<IResponse<ICategoryAndArticleResult>> {
    return new Promise((resolve) => {
      request({
        url: `/categories/path/${path}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

export const ApiMessage = {
  getMessageByPage(
    page: number,
    pageSize: number
  ): Promise<IResponse<IPage<IUserMessage>>> {
    return new Promise((resolve) => {
      request({
        url: `/messages/page?page=${page}&page_size=${pageSize}`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  getMessage(): Promise<IResponse<IUserMessage[]>> {
    return new Promise((resolve) => {
      request({
        url: `/messages`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  saveMessage(message: IUserMessage): Promise<IResponse<string>> {
    return new Promise((resolve) => {
      request({
        url: `/messages`,
        method: 'POST',
        data: message,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

export const ApiFriend = {
  createApply(apply: Apply): Promise<IResponse<ResultResponse<string>>> {
    return new Promise((resolve) => {
      request({
        url: `/friend/apply/create`,
        method: 'POST',
        data: apply,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  updateApply(apply: Apply): Promise<IResponse<ResultResponse<string>>> {
    return new Promise((resolve) => {
      request({
        url: `/friend/apply/update`,
        method: 'POST',
        data: apply,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  getFriendData(): Promise<IResponse<Array<Friend>>> {
    return new Promise((resolve) => {
      request({
        url: `/friend`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

export const ApiSetting = {
  getNotice(): Promise<IResponse<ResultResponse<string>>> {
    return new Promise((resolve) => {
      request({
        url: `/notice`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

/**
 * 评论API
 */
export const ApiComment = {
  saveComment(
    articleId: string,
    content: EditorContent
  ): Promise<IResponse<number>> {
    return new Promise((resolve) => {
      request({
        url: `/comment`,
        method: 'POST',
        data: {
          articleId: articleId,
          nickname: content.nickname,
          content: content.content,
          contact: content.contact,
          profile: content.profile,
        },
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};

export const ApiCommon = {
  /**
   *
   * @param params 图片文件
   * @returns
   */
  upload(params: FormData): Promise<IResponse<ResultResponse<string>>> {
    return new Promise((resolve) => {
      request({
        url: `/common/upload`,
        method: 'POST',
        data: params,
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
  getSetting():  Promise<IResponse<SystemSetting>> {
    return new Promise((resolve) => {
      request({
        url: `/common/setting`,
        method: 'GET',
      }).then((res) => {
        resolve(res.data);
      });
    });
  },
};
